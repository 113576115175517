
.payment-card{
    margin: 16px 0;
}

.no-data{
    padding: 96px;
    text-align: center;
    background: #f9fafb;
    font-weight: 600;
}