.wizard_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

/* Header  */
#progressBar,
#wizardRow,
#WizardFooter {
  width: 100%;
}
.progressbar-list::before {
  content: " ";
  background-color: rgb(155, 155, 155);
  border: 10px solid #fff;
  border-radius: 50%;
  display: block;
  width: 30px;
  height: 30px;
  margin: 9px auto;
  box-shadow: 1px 1px 3px #606060;
  transition: all;
}
.progressbar-list::after {
  content: "";
  background-color: rgb(155, 155, 155);
  padding: 0px 0px;
  position: absolute;
  top: 14px;
  left: -50%;
  width: 100%;
  height: 2px;
  margin: 9px auto;
  z-index: -1;
  transition: all 0.8s;
}
.progressbar-list.active::after {
  background-color: var(--primary-color);
}
.progressbar-list:first-child::after {
  content: none;
}
.input-group-addon {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  padding: 8px 12px;
  border-radius: 4px;
  height: fit-content;

}
.domain_input{
  display: flex;
  gap: 6px;
  position: relative;
}
.input_holeder{
  width: 100%;
  position: relative;
}
.input_holeder i{
  position: absolute;
  top: 22px;
  font-size: 18px;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.input_holeder .fa-circle-exclamation{
  color: #f00;
}
.input_holeder .fa-circle-check{
  color: #4bc27d;
}
.progressbar-list.active::before {
  font-family: "Font Awesome 5 free";
  content: "\f00c";
  font-size: 11px;
  font-weight: 600;
  color: #fff;
  padding: 6px;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  box-shadow: 0 0 0 7.5px rgb(176 60 70 / 11%);
}
.progressbar-list {
  color: #6f787d;
}
.active {
  color: #000;
}
/* Footer  */

.wizard_footer {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  padding: 24px;
}
.wizard_footer .wizard_btn {
  color: var(--white-color);
  background-color: var(--primary-color);
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  padding: 12px 30px;
  min-width: 180px;
  outline: 0;
  font-weight: bold;
  text-decoration: none;
  text-transform: none;
  font-size: 14px;
  text-align: center;
  border: none;
}
.wizard_footer .wizard_btn {
  color: #fff;
  -webkit-box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.3);
  -moz-box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.3);
  box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.3);
}
#progressBar {
  display: flex;
  justify-content: center;
}
/* Step 1  */
.flex-container {
  display: flex;
}
.flex-check,
.flex-title {
  flex: 1;
}
.cards-wrapper {
  width: 80%;
  padding: 24px;
}
.c-selection {
  box-sizing: border-box;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 60px;
  padding: 24px;
}

.c-selection-card {
  border: 2px solid #d6dde3;
  border-radius: 4px;
  padding: 15px;
  cursor: pointer;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 0;
  flex: 1;
}

.c-selection-card__heading {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1.85px;
  text-transform: uppercase;
}

.c-selection-card__price {
  color: #394956;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 32px;
  font-weight: 500;
}

.c-selection-card__footer {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.c-selection-card__body {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-selection-card__currency {
  font-size: 20px;
  margin-top: -4px;
}

.c-selection-card__frequency {
  color: #93a1af;
  font-size: 12px;
}

.c-selection-card:hover {
  background-color: rgba(0, 0, 0, 0.025);
  transition: all 0.1s ease-in-out;
}

.c-selection-card.selected {
  border: 2px solid #3096d5;
  transition: all 0.5s ease;
}

.selection-gap > * {
  margin-left: 20px;
}

.c-selection input[type="radio"] {
  height: 0;
  width: 0;
  display: none;
}

.c-selection input[type="radio"] + label {
  font-size: 24px;
  position: relative;
  cursor: pointer;
  display: flex;
  margin: 0;
  align-items: center;
  color: #9e9e9e;
  transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1);
}

.c-selection input[type="radio"] + label > .control__indicator {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
  width: 1em;
  height: 1em;
  background: transparent;
  border: 2px solid #d6dde3;
  border-radius: 2px;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
}

.c-selection input[type="radio"] + label:hover,
input[type="radio"]:focus + label {
  color: #fff;
}
.c-selection input[type="radio"] + label:hover > .control__indicator,
input[type="radio"]:focus + label > .control__indicator {
  background: rgba(255, 255, 255, 0.1);
}

.c-selection input[type="radio"]:checked + label > .control__indicator {
  border: 0.5em solid #3096d5;
  animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
}
.c-selection input[type="radio"]:checked + label > .control__indicator:before {
  content: "";
  position: absolute;
  top: 0.6em;
  left: 0.25em;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: checkbox-check 100ms 200ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
}

@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #fff;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 0.2em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 0.2em;
    height: 0.5em;
    border-color: #fff;
    transform: translate3d(0, -0.5em, 0) rotate(45deg);
  }
}

.footer-item-right {
  color: #9b9b9b;
  font-size: 12px;
  margin-right: 10px;
}

.footer-item-left {
  color: #4bc27d;
  font-weight: 500;
  font-size: 12px;
  margin-left: 10px;
}

.text-grey {
  color: #9b9b9b;
  font-weight: 400;
}

/* Step 2  */

.billing_cont .jusify-content-center {
  display: flex;
  justify-content: center;
}

.password_cont {
  position: relative;
}
.password_cont input {
  margin-bottom: 20px;
  padding: 8px;
  height: auto;
}
.password_cont i {
  position: absolute;
  top: 44px;
  right: 12px;
  cursor: pointer;
  color: var(--primary-color);
}

/* Step 3  */

.expand-custom {
  background: #f9f9f9;
  padding: 30px !important;
  margin-bottom: 30px;
  border-radius: 10px;
  -webkit-box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
}
.list.s1 {
  padding-left: 6px;
}
.list.s1 li {
  list-style: none;
  margin-bottom: 10px;
}
.description_title {
  font-weight: 600;
  color: var(--text-color-gray);
  font-family: "Nunito", Helvetica, Arial, sans-serif;
}
.description_value {
  font-weight: 600;
  color: var(--text-color-dark-gray);
  min-width: 150px;
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  text-wrap: wrap;
}
.list.s1 li:first-child {
  margin-top: 16px;
}
.list.s1 li:before {
  font-family: FontAwesome;
  content: "\f00c";
  margin-right: 10px;
  color: var(--primary-color);
}
.list.s1 li {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Align items with equal space between them */
}

.list.s1 li .description_title {
  flex: 1; /* Allow the title to take up remaining space */
}
.expand-custom h4 {
  margin: 0;
  margin-bottom: 5px;
}

.expand-custom p {
  margin: 0;
}

.expand-custom .table {
  display: table;
}

.expand-custom .toggle {
  display: block;
  margin-top: 10px;
  float: right;
  cursor: pointer;
}
.expand-custom .toggle:before {
  font-family: "FontAwesome";
  content: "\f055";
  font-size: 32px;
}

.expand-custom .toggle.clicked:before {
  content: "\f056";
}

.expand-custom .c1,
.expand-custom .c2,
.expand-custom .c3 {
  display: table-cell;
  vertical-align: middle;
}
.expand-custom .c1,
.expand-custom .c2 {
  padding-right: 30px;
}
.expand-custom .c1 img {
  width: 80px;
}

.expand-custom .c1 {
  width: 10%;
}
.expand-custom .c2 {
  width: 80%;
}
.expand-custom .c3 {
  width: 10%;
}

.expand-custom .details {
  display: none;
  margin-top: 30px;
}

.expand-custom .box-custom {
  background: rgba(0, 0, 0, 0.025);
  padding: 40px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
.box-custom .fa-hand-point-right {
  color: var(--primary-color);
  margin: 0 16px;
}

@media screen and (max-width: 1200px) {
  .m-w-100 {
    width: 100% !important;
  }
}
@media screen and (max-width: 1000px) {
  .c-selection.cards-wrapper {
    flex-direction: column;
    gap: 12px;
    padding: 0px !important;
  }

  .c-selection.cards-wrapper .c-selection-card {
    margin: 0px;
  }
  .c-selection.cards-wrapper .col-md-9 {
    width: 100%;
  }
  .c-selection.cards-wrapper .p-4 {
    padding: 6px !important;
  }
  .details .row {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .details .row .col-md-6{
    width: 100% !important;
  }
}

@media screen and (max-width: 600px) {
  .wizard_wrapper {
    padding: 3px !important;
  }
  #wizardRow {
    padding: 3px !important;
    padding-bottom: 24px !important;
  }
  .wizard_footer {
    flex-direction: column;
  }
  .wizard_footer .wizard_btn {
    width: 100%;
  }
  .expand-custom .box-custom{
    padding: 12px;
  }
}
@media screen and (max-width: 400px) {
  #progressBar .w-25 {
    width: 100% !important;
  }
  .list.s1 li{
    flex-wrap: wrap;
  }
  .description_title{
    flex: auto;
  }
  .list.s1 li i{
    display: none;
  }
}

.input-loader {
  border: 4px solid var(--primary-color);
  border-left-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: 10px;
}

.input-loader {
  border: 4px solid var(--primary-color);
  border-left-color: transparent;
  width: 22px;
  height: 22px;
}

.input-loader {
  border: 4px solid var(--primary-color);
  border-left-color: transparent;
  width: 22px;
  height: 22px;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}