.call-to-action {
    background: url("../../assets/cta-bg.png") center center / cover no-repeat;
  }
  .call-to-action h2 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1px;
    text-align: start;
    font-weight: bold;
  }
  .call-to-action p {
    text-align: start;
  }
  .phone-num-big {
    display: flex;
    flex-direction: column;
  }
  
  .pnb-text {
    display: block;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 5px;
    color: var(--text-color-gray);
  }
  .pnb-num {
    display: block;
    font-size: 36px;
    color: var(--text-color-dark-gray);
    font-weight: bold;
    margin: 10px 0 20px 0;
  }
  .phone-num-big i {
    font-size: 36px;
    display: block;
    padding: 24px 0;
  }