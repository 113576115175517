
:root {
  --primary-color: #017DFC;
  --secondary-color: #FFD943;
  --tertiary-color: #06D2FF;
  --primary-color-rgb: 23,155,255;
  --text-color-gray: #8492a6;
  --text-color-dark-gray: #35404e;
  --white-color: #fff;
}
body {
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-color-gray);
  line-height: 1.7em;
  padding: 0;
}
p {
  margin-bottom: 20px;
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-color-gray);
  line-height: 1.7em;
  padding: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Loader  */

.dots-container {
  position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f7f7f7;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dot {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #b3d4fc;
  animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
  margin-right: 0;
}

.dot:nth-child(1) {
  animation-delay: -0.3s;
}

.dot:nth-child(2) {
  animation-delay: -0.1s;
}

.dot:nth-child(3) {
  animation-delay: 0.1s;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    background-color: #b3d4fc;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }

  50% {
    transform: scale(1.2);
    background-color: #6793fb;
    box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
  }

  100% {
    transform: scale(0.8);
    background-color: #b3d4fc;
    box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
  }
}


/* BTN LOADER  */
.btn-loader {
  width: 55px;
  display: flex;
  justify-content: center;
  height: 20px;
  background: linear-gradient(#0000 calc(1*100%/6),#fff 0 calc(3*100%/6),#0000 0),
            linear-gradient(#0000 calc(2*100%/6),#fff 0 calc(4*100%/6),#0000 0),
            linear-gradient(#0000 calc(3*100%/6),#fff 0 calc(5*100%/6),#0000 0);
  background-size: 10px 400%;
  background-repeat: no-repeat;
  animation: matrix 1s infinite linear;
}

@keyframes matrix {
  0% {
    background-position: 0% 100%, 50% 100%, 100% 100%
  }

  100% {
    background-position: 0% 0%, 50% 0%, 100% 0%
  }
}


/* Error page  */

#errorPage{
  background: url("./assets/FaqBG.png") center bottom / cover;
  overflow: hidden;
  padding: 200px 0 150px 0;
  position: relative;
  margin-top: -100px;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}