.faq-container #subheader {
  background: url("../../assets/FaqBG.png") center bottom / cover;
  text-align: center;
  overflow: hidden;
  padding: 200px 0 150px 0;
  position: relative;
  margin-top: -100px;
}
.faq-container #subheader h1 {
  margin-top: 50px;
  margin-bottom: 10px;
  color: var(--text-color-dark-gray);
  font-weight: bold;
  font-family: "Nunito", Helvetica, Arial, sans-serif;
}

/* Acordion  */
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--white-color);
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card-header a {
  text-decoration: none;
}
.accordion-style-1 {
  overflow: visible;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion-style-1 .card {
  margin-bottom: 20px;
  border: none;
  -webkit-box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  overflow: hidden;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}
.bg-white {
  background: var(--white-color);
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.collapsible-link::before {
  content: "";
  width: 14px;
  height: 2px;
  background: #333;
  position: absolute;
  top: calc(50% - 1px);
  right: 1rem;
  display: block;
  transition: all 0.3s;
}
.collapsible-link::after {
  content: "";
  width: 2px;
  height: 14px;
  background: #333;
  position: absolute;
  top: calc(50% - 7px);
  right: calc(1rem + 6px);
  display: block;
  transition: all 0.3s;
}
.collapsible-link::before,
.collapsible-link::after {
  transition: transform 0.3s ease; /* Adding a transition effect */
}
.collapsible-link[aria-expanded="true"]::before {
  transform: rotate(180deg);
}
.collapsible-link[aria-expanded="true"]::after {
  transform: rotate(90deg) translateX(-1px);
}
.collapse {
  transition: max-height 0.6s ease; /* Adjust the transition duration and timing function as needed */
  overflow: hidden;
  max-height: 0; /* Initially collapse the content */
}

.collapse.show {
  max-height: 300px; /* Adjust the maximum height for expansion */
}

.collapse.show {
  max-height: 300px;
  transition: max-height 0.6s ease;
}
.collapse.close {
  max-height: 300px;
  transition: max-height 0.6s ease;
}
.collapse.close.show {
  max-height: 0; /* Collapse the content smoothly */
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
.faq-container .id-color {
  color: var(--primary-color);
}
.faq-container .bg-color-3{
    background: #342A6D;
}
.de_count {
    text-align: center;
    padding: 20px 0 10px 0;
    background: none;
}
.de_count h3 {
    font-size: 40px;
    letter-spacing: 0px;
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
    line-height: .5em;
    font-weight: 600;
}
#section-fun-facts{
    padding: 36px;
}
