footer.footer-light {
  background-color: #f5f9fd;
  color: var(--text-color-gray);
  
}
footer {
  background: #071c26;
  color: var(--white-color);
  padding: 80px 0 40px 0;
  display: block;
  text-align: left;
  line-height: 28px;
}
*,
::after,
::before {
  box-sizing: border-box;
}

.container {
  /* width: 100%; */
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-lg-4 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.widget {
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.spacer-20 {
  width: 100%;
  height: 20px;
  display: block;
  clear: both;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-bottom: 20px;
}

.col-lg-2 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

footer .widget h5 {
  margin-bottom: 20px;
}

h5,
footer.footer-light h5 {
  margin-top: 0;
    font-family: "Nunito", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: var(--text-color-dark-gray);
}

footer h5 {
  color: var(--white-color);
  font-size: 16px;
  text-transform: none;
}

.widget ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
footer.footer-light a {
  color: var(--text-color-gray);
  text-decoration: none;
  font-weight: 600;
}
footer.footer-light a:hover {
   color: var(--primary-color);
}
footer.footer-light p{
  font-size: 16px;
    font-weight: 600;
    line-height: 1.7em;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}
#form_subscribe #btn-submit i{
	text-align:center;
	font-size:30px;
	float:left;
	width:20%;
	background:var(--primary-color);
	color:#ffffff;
	display:table-cell;
	padding:5px 0 6px 0;
	border-radius:0 60px 60px 0 ;
	-moz-border-radius:0 60px 60px 0 ;
	-webkit-border-radius:0 60px 60px 0 ;
}

#form_subscribe input[type="text"] {
  padding: 8px 12px 9px 12px;
  width: 80%;
  float: left;
  display: table-cell;
  border-radius: 60px 0 0 60px;
  -moz-border-radius: 60px 0 0 60px;
  -webkit-border-radius: 60px 0 0 60px;
  border: none;
  background: rgba(255, 255, 255, 0.7);
  -webkit-box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
  -moz-box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
  box-shadow: 2px 2px 20px 0px rgba(20, 20, 20, 0.05);
}
.form-control {
  display: block;
  /* width: 100%; */
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: var(--white-color);
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.col {
  position: relative;
  /* width: 100%; */
  padding-right: 15px;
  padding-left: 15px;
}

.text-center {
  text-align: center !important;
}
.mt10 {
  margin-top: 10px;
}

.social-icons {
    display: flex;
    justify-content: flex-end;
}
.social-icons a{
  transition: .3s;
}
.social-icons i {
    text-shadow: none;
    color: var(--white-color);
    padding: 12px 10px 8px 10px;
    width: 34px;
    height: 34px;
    text-align: center;
    font-size: 16px;
    border-radius: 60px;
    -moz-border-radius: 60px;
    -webkit-border-radius: 60px;
    margin: 0 5px 0 5px;
    background-color: var(--primary-color);
}
.fa-lg {
    font-size: 1.33333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.social-icons i:hover {
    background: var(--white-color);
    border-color: #eceff3;
    color: var(--primary-color) !important;
}