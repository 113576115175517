.wrapper {
  overflow: hidden;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.custom-container{
  width: 80%;
  margin: 0 auto;

}
.row {
  display: flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
header {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 1001;
  margin: 0;
  height: 0;
  top: 0;
  height: 90px;
  transition: 0.7s;
}
header div#logo {
  display: inline-block;
  height: 100%;
  font-family: "Oswald", sans-serif;
  color: white;
  line-height: 86px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

#mainmenu {
  display: inline-block;
  font-size: 16px;
  margin: 0 auto;
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  float: none;
  padding-right: 20px;
  height: 70px;
}

#mainmenu li {
  margin: 0px 0px;
  padding: 0px 0px;
  float: left;
  display: inline;
  list-style: none;
  position: relative;
}

.header-light #mainmenu > li > a {
  color: #838383;
  font-weight: 600;
}

#mainmenu a {
  display: inline-block;
  padding: 30px 18px;
  padding-top: 29px;
  text-decoration: none;
  color: var(--white-color);
  text-align: center;
  outline: none;
  border-radius: 5px;
}

#mainmenu li ul {
  width: 210px;
  height: auto;
  position: absolute;
  left: 0px;
  z-index: 10;
  visibility: hidden;
  opacity: 0;
}
#mainmenu ul {
  margin: 0px 0px;
  padding: 10px;
  height: 30px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  overflow: hidden;
  background: var(--white-color);
  -webkit-box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
  -moz-box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
  box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
}

#mainmenu li li {
  display: block;
  float: none;
  text-transform: none;
}
#mainmenu li li {
  font-size: 14px;
}
a.btn-fx:after,
a.btn-fx:hover:after,
a.btn-fx:before,
a.btn-fx:hover:before,
#mainmenu li,
#mainmenu li ul,
.widget-post li .post-date {
  -o-transition: 0.2s ease;
  -ms-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  outline: none;
}
/* header.transparent {
  background: transparent;
  z-index: 999;
  position: relative;
} */
#mainmenu li li a {
  padding: 5px 15px;
  border-top: none;
  color: var(--text-color-dark-gray);
  width: 100%;
  border-left: none;
  text-align: start;
  font-weight: normal;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

.header-col-right {
  text-align: right;
}


.btn-custom {
  color: var(--white-color);
  background-color: var(--primary-color);
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  padding: 12px 30px;
  min-width: 120px;
  outline: 0;
  font-weight: bold;
  text-decoration: none;
  text-transform: none;
  font-size: 14px;
  text-align: center;
}

header a.btn-custom {
  padding: 8px 20px;
}
#menu-btn {
  display: none;
  float: right;
  margin-top: -3px;
  width: 32px;
  height: 32px;
  padding: 4px;
  text-align: center;
  cursor: pointer;
  color: var(--white-color);
  margin-left: 20px;
}
#menu-btn:before {
  font-family: FontAwesome;
  content: "\f0c9";
  font-size: 20px;
}
#menu-btn:hover {
  background: var(--white-color);
}
#menu-btn:hover:before {
  color: #222;
}
.nav-active a{
  color:  #0d6efd !important;
}
header.smaller {
  top: 0;
  position: fixed;
  background: var(--white-color) !important;
  padding: 0;
  width: 100%;
  box-shadow: 0 4px 6px 0 rgba(10, 10, 10, 0.05);
}
header.smaller.scroll-light {
  background: var(--white-color);
}

/* Mobile  */
header.header-mobile.header-light,
header.header-mobile.header-light #menu-btn,
header.header-mobile.header-light #menu-btn:hover {
  background: none;
}
header.header-mobile.header-light #menu-btn {
  color: #222;
  display: block;
}
header.header-mobile {
  position: absolute;
  background: var(--white-color);
  top: 0;
  height: 90px;
}
header.header-mobile.has-topbar {
  height: 100px;
}
header.header-mobile.has-topbar .header-row,
header.header-mobile.has-topbar .header-col {
  margin-top: 10px;
}
header.header-mobile.has-topbar #topbar {
  margin-top: -15px;
  padding: 0;
}
header.header-mobile nav {
  float: none;
}
header.header-mobile .logo {
  display: none;
}
header.header-mobile .btn-rsvp {
  padding-top: 40px;
  height: 90px;
}
header.header-mobile .logo-2 {
  display: inline-block;
}
header.header-mobile.header-light {
  background: var(--white-color);
}
header.header-mobile #mainmenu ul {
  padding: 0;
}
header.header-solid.header-light {
  background: var(--white-color);
}
header.header-mobile-sticky {
  position: fixed;
}
header.header-mobile {
  overflow: hidden;
  position: relative;
}
header.header-mobile #mainmenu {
  float: none;
  z-index: 200;
  width: 100%;
  height: fit-content;
  margin: 0;
  padding: 0;
  text-align: left;
  padding-right: 30px;
}
header.header-mobile #mainmenu a {
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
}
header.header-mobile #mainmenu a:hover {
  background: none;
}
header.header-mobile #mainmenu li ul {
  display: block;
  position: inherit;
  margin: 0;
  width: 100%;
}
header.header-mobile #mainmenu li {
  border-bottom: solid 1px #eee;
  margin: 0;
  width: 100%;
  display: block;
}
header.header-mobile #mainmenu li ul.mega ul {
  height: auto;
}
header.header-mobile #mainmenu li ul.mega .menu-content {
  background: var(--white-color);
}
header.header-mobile #mainmenu li a:after {
  display: none;
}
header.header-mobile #mainmenu li:last-child {
  margin-bottom: 30px;
}
header.header-mobile #mainmenu li ul {
  border-top: none;
  top: auto;
}
header.header-mobile #mainmenu li ul a {
  width: 100%;
  background: none;
  border: none;
}
header.header-mobile #mainmenu li ul li {
  border: none;
  padding-left: 40px;
}
header #mainmenu li ul li:hover {
  background:  #eee;
  border-radius: 6px;
}
header.header-mobile #mainmenu li ul li a {
  display: block;
  border-bottom: solid 1px #eee;
}
header.header-mobile #mainmenu li ul li:last-child {
  border-bottom: none;
  margin: 0;
}
header.header-mobile #mainmenu li ul li:last-child a {
  border-bottom: none;
}
header.header-mobile #mainmenu li a {
  color: #888;
}
header.header-mobile #mainmenu li a:hover {
  color: #333;
}
header.header-mobile #mainmenu li li a:hover {
  color: #888;
}
header.header-mobile #mainmenu li ul {
  height: 0;
  overflow: hidden;
  position: relative;
  left: 0;
}
header.header-mobile #mainmenu li ul li ul li:last-child {
  border-bottom: solid 1px #eee;
}
header.header-mobile #mainmenu li ul li ul {
  margin-bottom: 10px;
}
header.header-mobile #mainmenu > li > span {
  width: 36px;
  height: 36px;
  background: url("../../assets/arrow-down.png") center no-repeat;
  position: absolute;
  right: 0;
  margin-top: 10px;
  z-index: 1000;
  cursor: pointer;
}
header.header-mobile #mainmenu li span.active {
  background: url("../../assets/arrow-up.png") center no-repeat;
}
header.header-mobile #mainmenu li > ul > li > span {
  width: 36px;
  height: 36px;
  background: url("../../assets/arrow-down.png") center no-repeat;
  position: absolute;
  right: 0;
  margin-top: -45px;
  z-index: 1000;
  cursor: pointer;
}
header.header-mobile #mainmenu > li > ul > li > span.active {
  background: url("../../assets/arrow-up.png") center no-repeat;
}
header.header-mobile #mainmenu li ul li a {
  border-bottom: solid 1px #eee;
}
header.header-mobile #mainmenu li ul li:last-child > a {
  border-bottom: none;
}
#mainmenu li:hover>ul {
    visibility: visible;
    opacity: 1;
    margin-top: 0px;
}
@media screen and (max-width: 1300px) {
  .custom-container{
    width: 96%;
  }
}
@media only screen and (max-width: 992px) {
  .header-col-mid {
    position: absolute;
    top: 90px;
    width: 100%;
    padding: 0 0 0 0;
  }
  .nav-active {
    border-bottom: solid 1px #0d6efd !important;
  }
}
.action-btns{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

}
.lang_cont{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.lang_cont ul{
  padding-left: 12px;
}
.lang_cont li{
  list-style: none;
}
li.has-children>a>img {
    height: 30px;
    width: 30px;
}