.no-bottom #subheader{
    background: url("../../assets/contactBg.png") center bottom / cover;
    text-align: center;
    overflow: hidden;
    padding: 200px 0 150px 0;
    position: relative;
    margin-top: -100px;
}
#content {
    background: var(--white-color);
}
#content {
    width: 100%;
    background: var(--white-color);
    padding: 90px 0 90px 0;
    z-index: 100;
}
.no-top {
    padding-top: 0 !important;
}
.no-bottom {
    padding-bottom: 0 !important;
}
#subheader {
    text-align: center;
    overflow: hidden;
    padding: 100px 0 100px 0;
}
section {
    padding: 90px 0 90px 0;
    position: relative;
    display: block;
}
.center-y {
    position: relative;
}
.text-center {
    text-align: center !important;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.col-md-8, .col-md-12, .col-lg-8, .col-md-6{
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
#subheader h1 {
    line-height: 56px;
    letter-spacing: -1px;
    font-size: 50px;
    margin-top: 50px;
    margin-bottom: 10px;
    color: var(--text-color-dark-gray);
    font-weight: bold;
    font-family: "Nunito", Helvetica, Arial, sans-serif;
}
.no-top {
    background: url("../../assets/contactBg2.png") center top / cover ;
  }

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}
.contact-h3 {
    margin-bottom: 25px;
    margin-top: -50px;
    font-size: 20px;
 
    font-family: "Nunito", Helvetica, Arial, sans-serif;
    font-weight: bold;
    color: var(--text-color-dark-gray);
}
.no-top form {
    display: block;
    margin-top: 0em;
    unicode-bidi: isolate;
}
#contact_form input{
    -webkit-transition: .7s;
    transition: .7s;
    outline: none;
}
.form-border input[type=text]{
    padding: 8px;
    margin-bottom: 20px;
    border: none;
    border: solid 1px #cccccc;
    background: none;
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    height: auto;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    color: #333;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: var(--white-color);
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
input {
    overflow: visible;
}
#contact_form textarea {
    height: 195px;
    background: none;
}
.form-border input[type=text] {
    padding: 8px;
    margin-bottom: 20px;
    border: none;
    border: solid 1px #cccccc;
    background: none;
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    height: auto;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    color: #333;
}
.spacer-half {
    width: 100%;
    height: 15px;
    display: block;
    clear: both;
}
.btn-custom, #contact_form input{
    -webkit-transition: .7s;
    transition: .7s;
    outline: none;
}
.spacer-double {
    width: 100%;
    height: 60px;
    display: block;
    clear: both;
}
.mb30 {
    margin-bottom: 30px;
}
.box-rounded {
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
}
.padding40 {
    padding: 40px;
}