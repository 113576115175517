.pull-right {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
}

.pull-right a {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: 600;
  font-size: 16px;
}
.field-set label {
    color: var(--text-color-dark-gray);
    font-weight: 400;
    padding-bottom: 6px;
    font-family: "Nunito", Helvetica, Arial, sans-serif;
}
.btn-custom{
    background-color: var(--primary-color);
    color: var(--white-color);
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    padding: 12px 30px;
    min-width: 120px;
    outline: 0;
    font-weight: bold;
    text-decoration: none;
    text-transform: none;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-family: "Nunito", Helvetica, Arial, sans-serif;
    /* -webkit-box-shadow: 2px 2px 20px 0px rgba(20,20,20, 0.3); */
    border: none;
}

a.btn-custom:hover, .btn-custom:hover {
	color: #fff;
	-webkit-box-shadow: 2px 2px 20px 0px rgba(20,20,20, 0.3);
	-moz-box-shadow: 2px 2px 20px 0px rgba(20,20,20, 0.3);
	box-shadow: 2px 2px 20px 0px rgba(20,20,20, 0.3);
}
.btn-custom.btn-small {
	padding: 5px 20px 5px 20px;
	font-weight: bold;
	text-transform: uppercase;
}
.btn-fx.btn-custom {
	text-transform: normal;
}
.invalid{
    border-color: #d9534f !important;
}
.error{
    color: #d9534f;
    font-size: 16px;
    font-weight: 400;
    font-family: "Nunito", Helvetica, Arial, sans-serif;
    text-align: right;
    width: 100%;
    display: block;
}
.sign-btn{
    background-color: var(--secondary-color) !important;
    margin-right: 12px;
}
.btn-logout{
    background-color: red !important;
    min-width: auto !important;
    margin-left: 12px;
}
@media screen and (max-width: 600px) {   
    .action-btns .sign-btn {
        display: none;
       
    }
}