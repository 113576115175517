.pricing-container #subheader {
  background: url("../../assets/pricing-plans.png") center bottom / cover;
  text-align: center;
  overflow: hidden;
  padding: 200px 0 150px 0;
  position: relative;
  margin-top: -100px;
}
#content {
  background: #ffffff;
}
#content {
  width: 100%;
  background: #fff;
  padding: 90px 0 90px 0;
  z-index: 100;
}
.no-top {
  padding-top: 0 !important;
}
.no-bottom {
  padding-bottom: 0 !important;
}
.no-bottom {
  margin-bottom: 0;
  padding-bottom: 0;
}
#subheader {
  text-align: center;
  overflow: hidden;
  padding: 100px 0 100px 0;
}
section {
  padding: 90px 0 90px 0;
  position: relative;
  display: block;
}
.center-y {
  position: relative;
}
.text-center {
  text-align: center !important;
}

.pricing-container #subheader h1 {
  margin-top: 50px;
  margin-bottom: 10px;
  color: #35404e;
  font-weight: bold;
  font-family: "Nunito", Helvetica, Arial, sans-serif;
}
#table-container {
  background: url("../../assets/pricing-plans2.png") center top / cover;
}
.pricing-no-top {
  margin-top: -100px;
}
.switch-set div {
  display: inline-block;
  padding: 0 5px;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
.switch {
  margin-bottom: -5px;
  -webkit-appearance: none;
  height: 24px;
  width: 44px;
  background-color: #eeeeee;
  border-radius: 60px;
  position: relative;
  cursor: pointer;
}
input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  overflow: visible;
}
.switch::after {
  content: "";
  width: 20px;
  height: 20px;
  background-color: gray;
  position: absolute;
  border-radius: 100%;
  transition: 0.5s;
  margin: 2px 0 0 2px;
  background: var(--primary-color);
}
.switch-set div {
  display: inline-block;
  padding: 0 5px;
}
.spacer-20 {
  width: 100%;
  height: 20px;
  display: block;
  clear: both;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
}
.pricing-s1 {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.05);
}
.pricing-s1 .top {
  padding: 30px;
  text-align: center;
}
.pricing-s1 .top h2 {
  font-size: 32px;
  letter-spacing: normal;
  line-height: 1em;
  margin-bottom: 0;
  /* background: #017dfc; */
}
.pricing-s1 .top p.plan-tagline {
  margin: 0;
  padding: 0;
  font-size: 18px;
}
.pricing-s1 .mid {
  padding: 40px;
  padding-bottom: 10px;
  text-align: center;
}
.pricing-s1 .m,
.pricing-s1 .y {
  font-size: 48px;
  font-weight: 500;
}
.opt-1 {
  display: inline-block;
}
.opt-2 {
  display: none;
}
.pricing-s1 .bottom {
  border-top: 0px;
  text-align: center;
}
.pricing-s1 .bottom ul {
  display: block;
  list-style: none;
  list-style-type: none;
  margin: 30px 0;
  padding: 0px;
  text-align: left;
  overflow: hidden;
}
.pricing-s1 .bottom ul li {
  padding: 10px 30px;
  color: #505050;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
  unicode-bidi: isolate;
}
.pricing-s1 .bottom ul {
  display: block;
  list-style: none;
  list-style-type: none;
  margin: 30px 0;
  padding: 0px;
  text-align: left;
  overflow: hidden;
}

.pricing-s1 .bottom i {
  color: var(--primary-color);
  margin-right: 10px;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.fa-check:before {
  content: "\f00c";
}
.pricing-s1 .action {
  text-align: center;
  padding: 20px;
  background: #fcfcfc;
  border-top: solid 1px #eeeeee;
}
.pricing-s1 a.btn-custom {
  display: inline-block;
}
a.btn-custom,

a.btn-custom:visited,
.btn-custom {
  color: #fff;
  border-radius: 30px;
  -moz-border-radius: 30px;
  -webkit-border-radius: 30px;
  padding: 12px 30px;
  min-width: 120px;
  outline: 0;
  font-weight: bold;
  text-decoration: none;
  text-transform: none;
  font-size: 14px;
}
a.btn-custom,
.btn-custom {
  background: var(--primary-color);
}
.price {
  margin-bottom: 20px;
  font-family: "Nunito", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: var(--white-color);
  line-height: 1.7em;
  padding: 0;
}
.bg-color-4 {
  background: var(--tertiary-color);
}
.bg-color-3 {
  background: #342a6d;
}

