.about-us-section #subheader {
  background: url("../../assets/FaqBG.png") center bottom / cover;
  text-align: center;
  overflow: hidden;
  padding: 200px 0 150px 0;
  position: relative;
  margin-top: -100px;
}
.about-us-section #subheader h1 {
  margin-top: 50px;
  margin-bottom: 10px;
  color: var(--text-color-dark-gray);
  font-weight: bold;
  font-family: "Nunito", Helvetica, Arial, sans-serif;
}
.content-section{
    background: url("../../assets/6.png") center bottom / cover;
}
.about-us-section .bg-color-3{
    background: #292BA0;
}
.about-us-section .p-60{
    padding: 60px 0;
}
.content-section p{
    color: var(--text-color-gray);
    font-weight: 400;
    font-family: "Nunito", Helvetica, Arial, sans-serif;
}
.content-section .description_title{
    color: var(--text-color-dark-gray);
    font-weight: bold;
    font-family: "Nunito", Helvetica, Arial, sans-serif;
    letter-spacing: 1.2px;
}