/* .container-xl {
  margin-top: 9rem;
}
.nav-borders .nav-link.active {
  color: #0061f2;
  border-bottom-color: #0061f2;
}
.nav-borders .nav-link {
  color: #69707a;
  border-bottom-width: 0.125rem;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  margin-left: 1rem;
  margin-right: 1rem;
} */

.profile-nav{
    width: 35rem;
    margin-top: 1rem;
    font-family: "Nunito", Helvetica, Arial, sans-serif;
    font-weight: bold;
}
.profile-nav button{
 letter-spacing: 1px;
}
.profile-container{
    border-width:1px;
    border-radius:"lg";
    box-shadow: "2xl";
    margin-left: 25%;
}
#subheader-profile{
    background: url("../../assets/contactBg.png") center bottom / cover;
    text-align: center;
    overflow: hidden;
    padding: 300px 0 50px 0;
    position: relative;
    margin-top: -100px;
}
.content-container{
    display: flex !important;
    justify-content: center;
}
.tab-content {
    margin-top: 3rem;
}
.card-body {
    margin-bottom: 3rem;
}
.card-body label{
    font-weight: bold;
    font-family: "Nunito", Helvetica, Arial, sans-serif;
    color: var(--text-color-gray);
}

.control-label {
    font-weight: bold;
    font-family: "Nunito", Helvetica, Arial, sans-serif;
    color: var(--text-color-gray);
}
@media screen and(max-width:600px){
    .profile-nav{
        flex-direction: column !important;
    }
}