.no-top {
  padding-top: 0 !important;
}
section-services {
  padding: 90px 0 90px 0;
  position: relative;
  display: block;
  background-image: url("../../assets/servicesBg.png");
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
h2 {
  display: inline-block;
  overflow: hidden;
  position: relative;
  left: 0;
  right: 0;
}
h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -1px;
}
h2 .uptitle {
  display: block;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
}

.col-md-6 {
  position: relative;
  /* width: 100%; */
  padding-right: 15px;
  padding-left: 15px;
}
.text-center {
  text-align: center !important;
}
.mb30 {
  margin-bottom: 30px;
}
.f-box.f-icon-left {
  display: flex;
}
.f-box {
  -webkit-transition: 0.7s;
  transition: 0.7s;
  outline: none;
  
}
.f-box.f-icon-left i {
  margin-right: 30px;
  display: flex;
  margin-top: 5px;

}
.f-box.f-icon-rounded i {
  display: block;
  text-align: center;
  padding: 22px;
  width: 80px;
  height: 80px;
  border-radius: 10px;
}
.f-box i {
  font-size: 36px;
}
.bg-color-2 {
  background: var(--secondary-color);
}
.fa {
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.text-light {
  color: #f8f9fa !important;
}

.f-box.f-icon-left i {
  margin-right: 30px;
}
.f-box.f-icon-rounded i {
  display: block;
  text-align: center;
  padding: 22px;
  width: 80px;
  height: 80px;
  border-radius: 10px;
}
.bg-color {
  background: var(--primary-color);
}
.bg-color-1 {
  background: var(--primary-color);
}
h4 {
    font-size: 19px;
    margin-top: 0;
    margin-bottom: 10px;
}
.bg-color-3{
    background: var(--tertiary-color);
}
.fa-paper-plane-o:before {
  content: "\f1d9";
}
.fa-hdd-o:before {
  content: "\f0a0";
}
.fa-comments-o:before {
  content: "\f0e6";
}