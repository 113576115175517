#content {
    background: var(--white-color);
}

#content {
    width: 100%;
    background: var(--white-color);
    padding: 90px 0 90px 0;
    z-index: 100;
}
.no-top {
    padding-top: 0 !important;
}
.no-bottom {
    padding-bottom: 0 !important;
}
.no-bottom {
    margin-bottom: 0;
    padding-bottom: 0;
}

.vertical-center {
    min-height: 100%;
    min-height: 90vh;
    display: flex;
    align-items: center;
}
.full-height {
    overflow: hidden;
}
section {
    padding: 90px 0 90px 0;
    position: relative;
}
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.col-lg-5{
    position: relative;
    /* width: 100%; */
    padding-right: 15px;
    padding-left: 15px;
}
.id-color, h4.id-color {
    color: var(--primary-color);
}
/* h4 {
    font-size: 19px;
    margin-top: 0;
    margin-bottom: 10px;
} */
.h4 {
    margin-top: 0;
      font-family: "Nunito", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 19px;
    color: var(--text-color-dark-gray);
    margin-top: 0;
    margin-bottom: 0.5rem;
    line-height: 1.2;

}
h4 {
    display: block;
    /* margin-block-start: 1.33em;
    margin-block-end: 1.33em; */
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
.spacer-10 {
    width: 100%;
    display: block;
    clear: both;
}
h1{
    font-size: 52px;
    margin-bottom: 20px;
    line-height: 56px;
    letter-spacing: -1px;
}
h1{
    margin-top: 0;
    font-weight: 500;
    color: var(--text-color-dark-gray);
}
p.lead {
    font-size: 17px;
    line-height: 1.7em;
    margin-top: 0;
    font-weight: 600;
    color: var(--text-color-gray);
}
.lead {
    font-size: 1.25rem;
    font-weight: 300;
}
p {
    margin-bottom: 20px;
}
p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
.spacer-20 {
    width: 100%;
    height: 20px;
    display: block;
    clear: both;
}
a.btn-custom, .btn-custom {
    color: var(--white-color);
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    padding: 12px 30px;
    min-width: 120px;
    outline: 0;
    font-weight: bold;
    text-decoration: none;
    text-transform: none;
    font-size: 14px;
}
.btn-custom{
    -o-transition: .7s;
    -ms-transition: .7s;
    -moz-transition: .7s;
    -webkit-transition: .7s;
    transition: .7s;
    outline: none;
}
a.btn-custom, .btn-custom{
    background: var(--primary-color);
}

.bg-color-2, a.btn-custom.bg-color-2 {
    background:var(--secondary-color);
}
a.btn-custom, a.btn-custom:visited, .btn-custom {
    color: var(--white-color);
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    padding: 12px 30px;
    min-width: 120px;
    outline: 0;
    font-weight: bold;
    text-decoration: none;
    text-transform: none;
    font-size: 14px;
}
.img-fluid {
    max-width: 90%;
    height: auto;
}
img {
    outline: 0;
}
img {
    vertical-align: middle;
    border-style: none;
}
.home-no-top {
    background: url("../../assets/3d.png") center top / cover;
}
.relative {
    position: relative;
    z-index: 1;
}
h2 {
    display: inline-block;
    overflow: hidden;
    position: relative;
    left: 0;
    right: 0;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1px;
}